<template>
  <div class="homeViewByModule">
    <template v-if="loading">
      <AccordionPlay :defaultExpanded="true" class="mb-3" title="Carregando...">
        <div v-for="index in 1" :key="index">
          <div class="carouselView__grid">
            <slot name="carousel" :data="{}"></slot>
          </div>
          <div class="normalMode__grid">
            <slot name="normal" :data="{}"></slot>
          </div>
          <hr class="divisor" v-if="index !== 1 - 1" />
        </div>
      </AccordionPlay>
    </template>

    <div v-for="(category, key) in data" :key="key" :id="`accordion-${category.id}`">
      <AccordionPlay @toggle="
        getOpenedAccordionCategories === category.id
          ? actionOpenedAccordionCategories(null)
          : openedAccordion(category.id)
        " :defaultExpanded="getOpenedAccordionCategories === category.id" class="mb-3" :title="category?.title" :manageCategory="true">
        <template v-if="getLoadingModule">
          <div class="loading">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </template>
        <template v-else>
          <template v-if="category?.courses?.length === 0">
            <div class="not-found">
              <span class="not-found--icon">
                <IconSax name="lock" />
              </span>
              <div class="not-found--text"> Você não possui acesso aos cursos desta categoria.</div>
            </div>
          </template>
          <div v-for="(item, index) in category.courses" :key="index">

            <!-- CAROUSEL VIEW -->
            <div class="carouselView__grid">
              <slot name="carousel" :data="item"></slot>
            </div>

            <!-- NORMAL VIEW -->
            <div class="normalMode__grid">
              <slot name="normal" :data="item"></slot>
            </div>

            <!-- DIVISOR -->
            <hr class="divisor" v-if="index !== category.courses.length - 1" />
          </div>
        </template>
      </AccordionPlay>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import AccordionPlay from "@/components/common/AccordionPlay.vue";

export default {

  async mounted() {
    await this.$forceNextTick();

    setTimeout(() => {
      const loadFistItem = this.data[0]?.id;
      this.actionOpenedAccordionCategoriesNoScroll(loadFistItem);
    }, 1500);
  },

  components: {
    AccordionPlay,
  },
  computed: {
    ...mapGetters({
      getLoadingModule: "home/getLoadingModule",
      getOpenedAccordionCategories: "home/getOpenedAccordionCategories",
    }),
  },

  methods: {
    ...mapActions({
      actionOpenedAccordionCategories: "home/actionOpenedAccordionCategories",
      actionOpenedAccordionCategoriesNoScroll: "home/actionOpenedAccordionCategoriesNoScroll",
      actionFetchCourseAvailability: "home/actionFetchCourseAvailability",
    }),
    openedAccordion(id) {
      this.actionOpenedAccordionCategories(id)
    }
  },
  props: {
    loading: {
      type: Boolean,
    },
    controlRef: {
      type: String,
    },
    data: {
      type: Array,
      default: () => [],
    },
    movieCoverMode: {
      type: Boolean,
    },
    hideDisabledCourses: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  display: flex;
  justify-content: center;
  opacity: 0.5;

  ::v-deep .spinner-border {
    width: 1.3rem;
    height: 1.3rem;
  }
}

.not-found {
  display: flex;
  justify-content: center;
  gap: 10px;

  /* dark or light theme */


  &--icon {
    display: flex;
    align-items: center;

    ::v-deep svg {
      width: 20px;
      height: 20px;
    }

    ::v-deep svg path {
      fill: var(--fontcolor);
    }
  }

  &--text {
    display: flex;
    align-items: center;
    color: var(--fontcolor);
  }
}
</style>
